// src/utils/axios.js
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const auth = getAuth();

const axiosInstance = axios.create({
  baseURL: 'https://ellahotel.online:444/api',
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      try {
        const token = await user.getIdToken(true); // Force refresh the token
        config.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        console.error('Error getting Firebase ID token', error);
        return Promise.reject(error);
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken(true); // Force refresh the token
          // Update the original request with the new token
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          // Return a new promise with the updated request
          return axiosInstance(originalRequest);
        }
      } catch (err) {
        console.error('Error refreshing Firebase ID token', err);
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
