// src/utils/constants.js
export const materialsList = [
  "Cement", "Sand", "Gravel", "Crushed Stone", "Concrete", "Reinforcing Steel", "Structural Steel",
  "Bricks", "Blocks", "Wood", "Plywood", "Insulation", "Waterproofing Membranes", "Roofing Materials",
  "Paint", "Glass", "Aluminum", "PVC Piping", "Electrical Cables and Wires", "Flooring Materials", "Steel", "Asphalt",
  "Binding Wire", "Nails", "Water", "Miscellaneous Items", "Construction Equipment"
];

export const materialTypes = {
  "Cement": ["Portland Cement", "White Cement", "Hydraulic Cement"],
  "Sand": ["Mahaoya Sand", "Other Sand"],
  "Gravel": ["Crushed Gravel", "Pea Gravel"],
  "Crushed Stone": ["Granite", "Limestone"],
  "Concrete": ["Ready-Mix Concrete", "Precast Concrete"],
  "Reinforcing Steel": ["Rebar", "Mesh"],
  "Structural Steel": ["I-Beam", "H-Beam"],
  "Bricks": ["Clay Bricks", "Concrete Bricks"],
  "Blocks": ["Concrete Blocks", "Aerated Blocks"],
  "Wood": ["Hardwood", "Softwood"],
  "Plywood": ["Softwood Plywood", "Hardwood Plywood"],
  "Insulation": ["Fiberglass", "Foam"],
  "Waterproofing Membranes": ["Liquid Membranes", "Sheet Membranes"],
  "Roofing Materials": ["Asphalt Shingles", "Metal Roofing"],
  "Paint": ["Latex Paint", "Oil-Based Paint"],
  "Glass": ["Tempered Glass", "Laminated Glass"],
  "Aluminum": ["Sheets", "Bars"],
  "PVC Piping": ["Schedule 40", "Schedule 80"],
  "Electrical Cables and Wires": ["Copper Wire", "Aluminum Wire"],
  "Flooring Materials": ["Tile", "Laminate"],
  "Steel": ["T-20","T-10", "T-12", "T-16", "R-6" , "T-8"],
  "Asphalt": ["Hot Mix Asphalt", "Cold Mix Asphalt"],
  "Binding Wire": ["Galvanized Wire", "Annealed Wire"],
  "Nails": ["1 Inch", "2 Inch", "5 Inch"],
  "Water": ["Potable Water", "Non-Potable Water"],
  "Miscellaneous Items": [],
  "Construction Equipment": ["Excavators", "Bulldozers", "Cranes", "Forklifts", "Backhoes", "Vactor Machines", "Wheelbarrows"]
};

const defaultQuantityTypes = ["Cubes", "Kg", "Tons", "Pieces", "Rolls"];

export const quantityTypes = {
  "Cement": ["Bags", "Kg", "Tons"],
  "Sand": ["Cubes"],
  "Gravel": ["Cubes", "Tons"],
  "Crushed Stone": ["Cubes", "Tons"],
  "Concrete": ["Cubes", "Tons"],
  "Reinforcing Steel": ["Kg", "Tons", "Pieces"],
  "Structural Steel": ["Kg", "Tons", "Pieces"],
  "Bricks": ["Pieces", "Pallets"],
  "Blocks": ["Pieces", "Pallets"],
  "Wood": ["Cubic Feet", "Cubic Meters"],
  "Plywood": ["Sheets"],
  "Insulation": ["Rolls", "Sheets"],
  "Waterproofing Membranes": ["Rolls", "Sheets"],
  "Roofing Materials": ["Sheets", "Rolls"],
  "Paint": ["Liters", "Gallons"],
  "Glass": ["Sheets", "Panels"],
  "Aluminum": ["Kg", "Tons", "Sheets"],
  "PVC Piping": ["Meters", "Feet"],
  "Electrical Cables and Wires": ["Meters", "Feet", "Rolls"],
  "Flooring Materials": ["Square Meters", "Square Feet"],
  "Steel": ["Kg", "Tons", "Pieces"],
  "Asphalt": ["Tons"],
  "Binding Wire": ["Kg", "Rolls"],
  "Nails": ["Kg", "Pieces"],
  "Water": ["Liters", "Cubic Meters", "Gallons"],
  "Construction Equipment": ["Pieces", "Units", "Sets"],
  "Miscellaneous Items": [] 
};

export function getQuantityTypes(material) {
  return quantityTypes[material] || defaultQuantityTypes;
}
