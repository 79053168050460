import React, { useState, useEffect } from 'react';
import { getPhases, updatePhase } from '../utils/phaseApi';
import SelectPhaseModal from '../components/SelectPhaseModal';
import ReceiveMaterialModal from '../components/ReceiveMaterialModal';
import ReceivedHistoryModal from '../components/ReceivedHistoryModal';

const SupplyManagementDashboard = () => {
  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [dropdownSelectedPhase, setDropdownSelectedPhase] = useState(null);
  const [showSelectPhaseModal, setShowSelectPhaseModal] = useState(true);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [showReceiveMaterialModal, setShowReceiveMaterialModal] = useState(false);
  const [showReceivedHistoryModal, setShowReceivedHistoryModal] = useState(false);
  const [receivedMaterials, setReceivedMaterials] = useState({});
  const [errors, setErrors] = useState({});
  const [receivedFilter, setReceivedFilter] = useState('all'); // New filter state
  const [startDate, setStartDate] = useState(''); // Date filter start
  const [endDate, setEndDate] = useState(''); // Date filter end

  useEffect(() => {
    fetchPhases();
  }, []);

  const fetchPhases = async () => {
    try {
      const response = await getPhases();
      if (response && response.data && Array.isArray(response.data)) {
        setPhases(response.data);
      } else if (response && Array.isArray(response)) {
        setPhases(response);
      } else {
        console.error('Fetched data is not an array:', response.data);
      }
    } catch (error) {
      console.error('Error fetching phases:', error);
    }
  };

  const handleSelectPhase = (phase) => {
    setSelectedPhase(phase);
    setDropdownSelectedPhase(phase);
    setShowSelectPhaseModal(false);
  };

  const handleDropdownSelectPhase = (e) => {
    const selected = phases.find((phase) => phase._id === e.target.value);
    setDropdownSelectedPhase(selected);
    setSelectedPhase(selected);
  };

  const handleMaterialNameClick = (material) => {
    setSelectedMaterial(material);
    setShowReceiveMaterialModal(true);
  };

  const validateMaterial = (material) => {
    const newErrors = {};
    if (!material.receivedDate) newErrors.receivedDate = 'Received Date is required';
    if (!material.receivedTime) newErrors.receivedTime = 'Received Time is required';
    if (!material.vehicleNumber) newErrors.vehicleNumber = 'Vehicle Number is required';
    if (!material.receivedQuantity || isNaN(material.receivedQuantity) || parseFloat(material.receivedQuantity) <= 0) {
      newErrors.receivedQuantity = 'Valid Received Quantity is required';
    }
    if (!material.materialAcceptedBy) newErrors.materialAcceptedBy = 'Material Accepted By is required';
    return newErrors;
  };

  const handleReceiveMaterial = async (updatedMaterial) => {
    const newErrors = validateMaterial(updatedMaterial);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const newHistoryEntry = {
      receivedDate: updatedMaterial.receivedDate,
      receivedTime: updatedMaterial.receivedTime,
      vehicleNumber: updatedMaterial.vehicleNumber,
      receivedQuantity: updatedMaterial.receivedQuantity,
      materialAcceptedBy: updatedMaterial.materialAcceptedBy,
    };

    const updatedStockIncome = selectedPhase.stockIncome.map((material) =>
      material._id === updatedMaterial._id
        ? {
            ...material,
            receivedQuantity: (parseFloat(material.receivedQuantity) || 0) + parseFloat(newHistoryEntry.receivedQuantity),
            receivedHistory: [...(material.receivedHistory || []), newHistoryEntry],
          }
        : material
    );

    const updatedPhase = {
      ...selectedPhase,
      stockIncome: updatedStockIncome,
    };

    setSelectedPhase(updatedPhase);
    setErrors({});

    try {
      await updatePhase(selectedPhase._id, updatedPhase);
      alert('Received details saved successfully.');
      setShowReceiveMaterialModal(false);
      setReceivedMaterials({ ...receivedMaterials, [updatedMaterial._id]: true });
    } catch (error) {
      console.error('Error updating phase:', error);
    }
  };

  const calculateTotalReceived = (material) => {
    const receivedQuantities = selectedPhase.stockIncome
      .filter((m) => m._id === material._id)
      .map((m) => parseFloat(m.receivedQuantity) || 0);
    return receivedQuantities.reduce((total, qty) => total + qty, 0);
  };

  const isReceived = (material) => {
    const totalReceived = calculateTotalReceived(material);
    return totalReceived >= parseFloat(material.orderQuantity);
  };

  const filterByReceivedStatus = (materials) => {
    return materials.filter((material) => {
      if (receivedFilter === 'received') return isReceived(material);
      if (receivedFilter === 'not-received') return !isReceived(material);
      return true; // 'all' filter, show all materials
    });
  };

  const filterByDateRange = (materials) => {
    return materials.filter((material) => {
      if (!startDate && !endDate) return true;
      const receivedHistory = material.receivedHistory || [];
      return receivedHistory.some((entry) => {
        const receivedDate = new Date(entry.receivedDate);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        return (!start || receivedDate >= start) && (!end || receivedDate <= end);
      });
    });
  };

  const isMaterialReceivedCompletely = (material) => {
    return receivedMaterials[material._id] || false;
  };

  const uniqueMaterials = selectedPhase
    ? Array.from(new Map(selectedPhase.stockIncome.map((item) => [item._id, item])).values())
    : [];

  const filteredMaterials = filterByReceivedStatus(filterByDateRange(uniqueMaterials)).sort((a, b) => {
    const isReceivedA = isReceived(a);
    const isReceivedB = isReceived(b);
    return isReceivedA - isReceivedB; // Sort so that 'No' (false) appears first
  });

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };
  

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-6">
      {showSelectPhaseModal && (
        <SelectPhaseModal
          onClose={() => setShowSelectPhaseModal(false)}
          onSelect={handleSelectPhase}
        />
      )}
      {!showSelectPhaseModal && (
        <>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl md:text-3xl font-semibold text-gray-800">
              Supply Management Dashboard
            </h1>
            <select
              value={dropdownSelectedPhase ? dropdownSelectedPhase._id : ''}
              onChange={handleDropdownSelectPhase}
              className="p-2 border rounded"
              required
            >
              {phases.map((phase) => (
                <option key={phase._id} value={phase._id}>
                  {phase.name}
                </option>
              ))}
            </select>
          </div>

          {/* Filters */}
          <div className="flex flex-wrap gap-4 mb-6">
            {/* Received Status Filter */}
            <select
              className="p-2 border rounded"
              value={receivedFilter}
              onChange={(e) => setReceivedFilter(e.target.value)}
            >
              <option value="all">All Materials</option>
              <option value="received">Received Materials</option>
              <option value="not-received">Not Received Materials</option>
            </select>

            {/* Date Range Filter */}
            <div className="flex items-center space-x-2">
              <label className="text-sm">Start Date:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="p-2 border rounded"
              />
            </div>
            <div className="flex items-center space-x-2">
              <label className="text-sm">End Date:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="p-2 border rounded"
              />
            </div>
          </div>

          {selectedPhase && (
            <div className="space-y-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">Ordered Materials for {selectedPhase.name}</h2>
              {filteredMaterials.map((material, index) => (
                <div
                  key={material._id || index}
                  className={`bg-gray-400 p-4 rounded-lg shadow-md ${isReceived(material) ? 'bg-gray-400' : 'bg-white'}`}
                >
                  <h3 className="text-lg font-medium text-gray-700 mb-2">{material.materialName} {material.materialType}</h3>
                  <div className="text-sm text-gray-600 space-y-1">
                    <p><strong>Supplier:</strong> {material.supplier}</p>
                    <p><strong>Supplier Contact:</strong> {material.supplierContact}</p>
                    <p><strong>Order Quantity:</strong> {material.orderQuantitywithtype}</p>
                    {material.materialName === 'Steel' && (
                      <p><strong>Order Quantity (Pieces):</strong> {material.orderPieces || 'N/A'}</p>
                    )}
                    <p><strong>Received Quantity:</strong> {calculateTotalReceived(material)}</p>
                    <p><strong>Received:</strong> {isReceived(material) ? 'Yes' : 'No'}</p>
                    <p><strong>Ordered Date:</strong> {formatDate(material.orderedDate)}</p>

                  </div>
                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedMaterial(material);
                        setShowReceivedHistoryModal(true);
                      }}
                    >
                      View History
                    </button>
                    <button
                      className={`bg-green-500 text-white px-4 py-2 rounded ${isReceived(material) ? 'cursor-not-allowed' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isReceived(material)) {
                          handleMaterialNameClick(material);
                        }
                      }}
                      disabled={isReceived(material)}
                    >
                      Stock In
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          {showReceiveMaterialModal && selectedMaterial && (
            <ReceiveMaterialModal
              material={selectedMaterial}
              onClose={() => setShowReceiveMaterialModal(false)}
              onSave={handleReceiveMaterial}
              calculateTotalReceived={calculateTotalReceived}
              errors={errors}
            />
          )}

          {showReceivedHistoryModal && selectedMaterial && (
            <ReceivedHistoryModal
              material={selectedMaterial}
              onClose={() => setShowReceivedHistoryModal(false)}
              stockIncome={selectedPhase.stockIncome}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SupplyManagementDashboard;
