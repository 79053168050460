// src/utils/employeeApi.js

import axiosInstance from './axios';

const API_URL = '/employees'; // The base URL is already set in axiosInstance

export const getEmployees = () => {
  return axiosInstance.get(API_URL);
};

export const getEmployee = (id) => {
  return axiosInstance.get(`${API_URL}/${id}`);
};

export const addEmployee = (employee) => {
  return axiosInstance.post(API_URL, employee);
};

export const updateEmployee = (id, employee) => {
  return axiosInstance.put(`${API_URL}/${id}`, employee);
};

export const deleteEmployee = (id) => {
  return axiosInstance.delete(`${API_URL}/${id}`);
};

// Fetch employee attendance
export const getEmployeeAttendance = (employeeId) => {
  return axiosInstance.get(`${API_URL}/${employeeId}`);
};

// Add attendance for an employee
export const addAttendance = (employeeId, attendanceData) => {
  return axiosInstance.post(`${API_URL}/${employeeId}/attendance`, attendanceData);
};

// Fetch employee attendance for a specific date
export const getAttendanceByDate = (employeeId, date) => {
  return axiosInstance.get(`${API_URL}/${employeeId}/attendance`, {
    params: { date }
  });
};
