import React, { useState } from 'react';
import { addPhase } from '../utils/phaseApi';

const AddPhaseModal = ({ onClose, onNext }) => {
  const [phaseName, setPhaseName] = useState('');
  const [error, setError] = useState('');

  const handleAddPhase = async () => {
    if (phaseName.trim()) {
      try {
        const newPhase = await addPhase({ name: phaseName });
        onNext(newPhase);
      } catch (error) {
        console.error('Error adding phase:', error);
        setError('Failed to add phase. Please try again.');
      }
    } else {
      setError('Phase name is required.');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Add Phase</h2>
        <input
          type="text"
          value={phaseName}
          onChange={(e) => {
            setPhaseName(e.target.value);
            setError('');
          }}
          placeholder="Enter Phase Name"
          className="w-full p-2 border rounded mb-4"
        />
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <div className="flex justify-end">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleAddPhase}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPhaseModal;
