import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmployee, addAttendance, getAttendanceByDate } from '../utils/employeeApi';

const AddAttendance = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [formData, setFormData] = useState({
    date: '',
    enteredTime: '08:00', // Default Check-In Time to 08:00
    goesTime: '16:00', // Default Check-Out Time to 16:00
    taskType: '',
    taskDetail: '',
    taskExplanation: '',
    contractor: '',
  });
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployee = async () => {
    try {
      const response = await getEmployee(id);
      setEmployee(response.data);
    } catch (error) {
      console.error('Failed to fetch employee:', error);
    }
  };

  const fetchAttendanceRecords = async (date) => {
    try {
      const response = await getAttendanceByDate(id, date);
      setAttendanceRecords(response.data);
    } catch (error) {
      console.error('Failed to fetch attendance records:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'date') {
      fetchAttendanceRecords(value);
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.date) newErrors.date = 'Date is required';
    if (!formData.enteredTime) newErrors.enteredTime = 'Check-In Time is required';
    if (!formData.goesTime) newErrors.goesTime = 'Check-Out Time is required';
    if (!formData.taskType) newErrors.taskType = 'Task Type is required';
    if (!formData.taskDetail) newErrors.taskDetail = 'Task Detail is required';
    if (!formData.taskExplanation) newErrors.taskExplanation = 'Task Explanation is required';
    if (!formData.contractor) newErrors.contractor = 'Contractor is required';

    if (Object.keys(newErrors).length === 0) {
      const enteredTimeObj = new Date(`1970-01-01T${formData.enteredTime}:00Z`);
      const goesTimeObj = new Date(`1970-01-01T${formData.goesTime}:00Z`);

      for (const record of attendanceRecords) {
        const recordEnteredTime = new Date(`1970-01-01T${record.enteredTime}:00Z`);
        const recordGoesTime = new Date(`1970-01-01T${record.goesTime}:00Z`);

        if (
          (enteredTimeObj >= recordEnteredTime && enteredTimeObj < recordGoesTime) ||
          (goesTimeObj > recordEnteredTime && goesTimeObj <= recordGoesTime) ||
          (enteredTimeObj <= recordEnteredTime && goesTimeObj >= recordGoesTime)
        ) {
          newErrors.enteredTime = 'Check-In Time and Check-Out Time overlap with existing records';
          newErrors.goesTime = 'Check-In Time and Check-Out Time overlap with existing records';
          break;
        }
      }
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length === 0) {
      const task = `${formData.taskType} - ${formData.taskDetail}: ${formData.taskExplanation}`;

      try {
        await addAttendance(id, { ...formData, task });
        navigate('/employee-attendance-dashboard');
      } catch (error) {
        console.error('Error adding attendance:', error);
      }
    } else {
      setErrors(newErrors);
    }
  };

  if (!employee) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-5">
      {/* Sticky Header for Employee Name */}
      <div className="sticky top-0 bg-white z-10 py-2 border-b border-gray-300">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">Add Attendance for {employee.name}</h1>
        <button
          onClick={() => navigate('/employee-attendance-dashboard')}
          className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Back to Dashboard
        </button>
      </div>

      <div className="mb-6 mt-4">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Attendance Records for {formData.date}</h2>
        {attendanceRecords.length > 0 ? (
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Date</th>
                <th className="py-2 px-4 border-b">Check-In Time</th>
                <th className="py-2 px-4 border-b">Check-Out Time</th>
                <th className="py-2 px-4 border-b">Task</th>
                <th className="py-2 px-4 border-b">Contractor</th>
              </tr>
            </thead>
            <tbody>
              {attendanceRecords.map((record, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b">{new Date(record.date).toLocaleDateString()}</td>
                  <td className="py-2 px-4 border-b">{record.enteredTime}</td>
                  <td className="py-2 px-4 border-b">{record.goesTime}</td>
                  <td className="py-2 px-4 border-b">{record.task}</td>
                  <td className="py-2 px-4 border-b">{record.contractor}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-500">No attendance records for this date.</p>
        )}
      </div>

      <form onSubmit={handleSubmit} className="bg-gray-100 p-8 shadow rounded-lg">
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
            {errors.date && <p className="text-red-500 text-sm">{errors.date}</p>}
          </div>
          <div>
            <label htmlFor="enteredTime" className="block mb-2 text-sm font-medium text-gray-900">
              Check-In Time
            </label>
            <input
              type="time"
              id="enteredTime"
              name="enteredTime"
              value={formData.enteredTime}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
            {errors.enteredTime && <p className="text-red-500 text-sm">{errors.enteredTime}</p>}
          </div>
          <div>
            <label htmlFor="goesTime" className="block mb-2 text-sm font-medium text-gray-900">
              Check-Out Time
            </label>
            <input
              type="time"
              id="goesTime"
              name="goesTime"
              value={formData.goesTime}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
            {errors.goesTime && <p className="text-red-500 text-sm">{errors.goesTime}</p>}
          </div>
          <div>
            <label htmlFor="taskType" className="block mb-2 text-sm font-medium text-gray-900">
              Task Type
            </label>
            <select
              id="taskType"
              name="taskType"
              value={formData.taskType}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            >
              <option value="">Select Task Type</option>
              <option value="TDS Sheet Task">TDS Sheet Task</option>
              <option value="Out Task">Out Task</option>
            </select>
            {errors.taskType && <p className="text-red-500 text-sm">{errors.taskType}</p>}
          </div>
          {formData.taskType && (
            <div>
              <label htmlFor="taskDetail" className="block mb-2 text-sm font-medium text-gray-900">
                Task Detail
              </label>
              <select
                id="taskDetail"
                name="taskDetail"
                value={formData.taskDetail}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              >
                <option value="">Select Task Detail</option>
                <option value="Construction Work">Construction Work</option>
                <option value="Non-Construction Work">Non-Construction Work</option>
              </select>
              {errors.taskDetail && <p className="text-red-500 text-sm">{errors.taskDetail}</p>}
            </div>
          )}
          {formData.taskDetail && (
            <div>
              <label htmlFor="taskExplanation" className="block mb-2 text-sm font-medium text-gray-900">
                Task Explanation
              </label>
              <input
                type="text"
                id="taskExplanation"
                name="taskExplanation"
                value={formData.taskExplanation}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
              {errors.taskExplanation && <p className="text-red-500 text-sm">{errors.taskExplanation}</p>}
            </div>
          )}
          <div>
            <label htmlFor="contractor" className="block mb-2 text-sm font-medium text-gray-900">
              Contractor
            </label>
            <select
              id="contractor"
              name="contractor"
              value={formData.contractor}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            >
              <option value="">Select Contractor</option>
              <option value="Contractor Dilan">Contractor Dilan</option>
              <option value="Contractor Thusitha">Contractor Thusitha</option>
              <option value="None">None</option>
            </select>
            {errors.contractor && <p className="text-red-500 text-sm">{errors.contractor}</p>}
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddAttendance;
