import React, { useState, useEffect } from 'react';
import { getPhases, closePhase } from '../utils/phaseApi'; // Adjust the path as needed

const PhaseDashboard = () => {
  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [closingPhase, setClosingPhase] = useState(false);

  useEffect(() => {
    const fetchPhases = async () => {
      try {
        const data = await getPhases();  // Fetch phases from backend API
        setPhases(data || []);  // Ensure data is an array
        setLoading(false);
      } catch (error) {
        console.error('Error fetching phases:', error);
        setLoading(false);
      }
    };
    fetchPhases();
  }, []);

  const aggregateMaterials = (phase) => {
    const materialMap = {};

    if (phase.stockIncome && phase.stockIncome.length > 0) {
      phase.stockIncome.forEach((stock) => {
        const key = `${stock.materialName}-${stock.materialType}`;

        if (!materialMap[key]) {
          materialMap[key] = {
            materialName: stock.materialName,
            materialType: stock.materialType,
            quantityType: stock.quantityType,
            totalReceivedQuantity: parseFloat(stock.receivedQuantity) || 0,
            totalUsedQuantity: stock.usageHistory.reduce(
              (total, usage) => total + parseFloat(usage.usageQuantity),
              0
            ),
          };
        } else {
          materialMap[key].totalReceivedQuantity += parseFloat(stock.receivedQuantity) || 0;
          materialMap[key].totalUsedQuantity += stock.usageHistory.reduce(
            (total, usage) => total + parseFloat(usage.usageQuantity),
            0
          );
        }
      });
    }

    return Object.values(materialMap);
  };

  const formatNumber = (num) => {
    return Number.isInteger(num) ? num : num.toFixed(2);
  };

  const handlePhaseSelect = (phase) => {
    const aggregatedStockIncome = aggregateMaterials(phase);
    setSelectedPhase({ ...phase, aggregatedStockIncome });
  };

  const handleClosePhase = async () => {
    setClosingPhase(true);
    try {
      // Call backend API to close phase and save remaining materials
      const response = await closePhase(selectedPhase._id);
      setSelectedPhase({ ...selectedPhase, status: 'closed' });
      console.log(response.message);
    } catch (error) {
      console.error('Error closing phase:', error);
    } finally {
      setClosingPhase(false);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-500 border-solid"></div>
      </div>
    );
  }

  if (!selectedPhase) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
          <h2 className="text-2xl font-bold mb-4 text-center">Select a Phase</h2>
          <ul className="divide-y divide-gray-300">
            {phases.map((phase) => (
              <li
                key={phase._id}
                onClick={() => handlePhaseSelect(phase)}
                className="py-3 cursor-pointer hover:bg-gray-100 transition-colors px-4 rounded-md text-lg"
              >
                {phase.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50 p-6">
      <h1 className="text-3xl font-semibold mb-6 text-center">
        {selectedPhase.name} - Materials Dashboard
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl">
        {selectedPhase.aggregatedStockIncome.length > 0 ? (
          selectedPhase.aggregatedStockIncome.map((stock, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6 flex flex-col">
              <h3 className="text-xl font-semibold mb-2">{stock.materialName}</h3>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Material Type:</span> {stock.materialType || 'N/A'}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Total Received Quantity:</span> {formatNumber(stock.totalReceivedQuantity)} {stock.quantityType}
              </p>
              <p className="text-gray-600 mb-2">
                <span className="font-medium">Total Used Quantity:</span> {formatNumber(stock.totalUsedQuantity)} {stock.quantityType}
              </p>
              <p className="text-gray-600">
                <span className="font-medium">Remaining Quantity:</span> {formatNumber(stock.totalReceivedQuantity - stock.totalUsedQuantity)} {stock.quantityType}
              </p>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center text-gray-600">No data available</div>
        )}
      </div>

      {/* Close Phase Button */}
      <div className="mt-6">
        <button
          onClick={handleClosePhase}
          disabled={selectedPhase.status === 'closed' || closingPhase}
          className={`px-4 py-2 rounded text-white ${
            selectedPhase.status === 'closed' ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500 hover:bg-red-700'
          }`}
        >
          {closingPhase ? 'Closing Phase...' : selectedPhase.status === 'closed' ? 'Phase Closed' : 'Close Phase'}
        </button>
      </div>
    </div>
  );
};

export default PhaseDashboard;
