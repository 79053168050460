import axios from './axios';

const API_URL = '/phases';

// Fetch all phases
export const getPhases = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

// Fetch a specific phase by ID
export const getPhase = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);
  return response.data;
};

// Add a new phase
export const addPhase = async (phase) => {
  const response = await axios.post(API_URL, phase);
  return response.data;
};

// Update a phase by ID
export const updatePhase = async (id, phase) => {
  const response = await axios.patch(`${API_URL}/${id}`, phase);
  return response.data;
};

// Delete a phase by ID
export const deletePhase = async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`);
  return response.data;
};

// Close a phase and save remaining materials
export const closePhase = async (id) => {
  const response = await axios.post(`${API_URL}/${id}/close`);
  return response.data;
};

// Fetch all remaining materials
export const getRemainingMaterials = async () => {
  const response = await axios.get(`${API_URL}/remainingMaterials`);
  return response.data;
};

// Add remaining materials as a new entry in receivedHistory
export const addRemainingMaterial = async (id, materialData) => {
  const response = await axios.post(`${API_URL}/${id}/addRemainingMaterial`, materialData);
  return response.data;
};

// New method to delete remaining materials by ID
export const deleteRemainingMaterial = async (materialId) => {
  const response = await axios.delete(`${API_URL}/remainingMaterials/${materialId}`);
  return response.data;
};
