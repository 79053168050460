import React, { useState, useEffect } from 'react';
import { getQuantityTypes } from '../utils/constants';

const AddUsageModal = ({ material, onClose, onSave }) => {
  const [usageDate, setUsageDate] = useState('');
  const [usageQuantity, setUsageQuantity] = useState('');
  const [usageType, setUsageType] = useState(getQuantityTypes(material.materialName)[0]);
  const [remainingQuantity, setRemainingQuantity] = useState(0);

  useEffect(() => {
    // Calculate remaining quantity
    const totalReceived = parseFloat(material.receivedQuantity) || 0;
    const totalUsage = material.usageHistory?.reduce((sum, usage) => sum + parseFloat(usage.usageQuantity), 0) || 0;
    const remaining = totalReceived - totalUsage;
    setRemainingQuantity(remaining);
  }, [material]);

  const handleSave = () => {
    const parsedUsageQuantity = parseFloat(usageQuantity);

    if (!usageDate || isNaN(parsedUsageQuantity) || parsedUsageQuantity <= 0) {
      alert('Please enter valid usage details.');
      return;
    }

    const usageRecord = {
      materialId: material._id,
      materialName: material.materialName,
      usageDate,
      usageQuantity: parsedUsageQuantity,
      usageType,
    };

    onSave(usageRecord);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
        <h2 className="text-xl font-semibold mb-4">Add Usage for {material.materialName}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-gray-700">Usage Date</label>
            <input
              type="date"
              value={usageDate}
              onChange={(e) => setUsageDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Usage Quantity</label>
            <input
              type="number"
              value={usageQuantity}
              onChange={(e) => setUsageQuantity(e.target.value)}
              placeholder={`Remaining: ${remainingQuantity}`}
              className="w-full p-2 border rounded text-gray-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Quantity Type</label>
            <select
              value={usageType}
              onChange={(e) => setUsageType(e.target.value)}
              className="w-full p-2 border rounded"
            >
              {getQuantityTypes(material.materialName).map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUsageModal;
