import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import EmployeeDashboard from './pages/EmployeeDashboard';
import UpdateEmployee from './components/UpdateEmployee';
import AddEmployee from './components/AddEmployee';
import AddAttendance from './pages/AddAttendance';
import AttendanceDashboard from './pages/AttendanceDashboard';
import ViewAttendance from './pages/ViewAttendance';
import EstimationDashboard from './pages/EstimationDashboard';
import MaterialsPage from './components/MaterialsPage';
import OrderManagementDashboard from './pages/OrderManagementDashboard';
import OrderHistoryModal from './components/OrderHistoryModal';
import SupplyManagementDashboard from './pages/SupplyManagementDashboard';
import ReceivedHistoryModal from './components/ReceivedHistoryModal';
import UsageManagement from './pages/UsageManagementDashboard';
import SignIn from './pages/SignIn'; 
import PhaseDashboard from './pages/PhaseDashboard';
import { useAuth } from './AuthContext';

const App = () => {
  const { currentUser } = useAuth();

  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Default Route - Sign-in */}
        {!currentUser && <Route path="/" element={<SignIn />} />}
        

        {/* Public Routes */}
        <Route path="/signin" element={!currentUser ? <SignIn /> : <Navigate to="/" replace />} />

        {/* Protected Routes */}
        {currentUser && (
          <>
            <Route path="/workerdashboard" element={<EmployeeDashboard />} />
            <Route path="/update-employee/:id" element={<UpdateEmployee />} />
            <Route path="/add-employee" element={<AddEmployee />} />
            <Route path="/add-attendance/:id" element={<AddAttendance />} />
            <Route path="/view-attendance/:id" element={<ViewAttendance />} />
            <Route path="/employee-attendance-dashboard" element={<AttendanceDashboard />} />
            <Route path="/" element={<EstimationDashboard />} />
            <Route path="/materials/:phaseId" element={<MaterialsPage />} />
            <Route path="/order-management" element={<OrderManagementDashboard />} />
            <Route path="/order-history/:materialId" element={<OrderHistoryModal />} />
            <Route path="/supply-management" element={<SupplyManagementDashboard />} />
            <Route path="/received-history/:materialId" element={<ReceivedHistoryModal />} />
            <Route path="/usagemanagement" element={<UsageManagement />} />
            <Route path="/phasedashboard" element={<PhaseDashboard />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default App;
