import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getEmployeeAttendance } from '../utils/employeeApi';

const ViewAttendance = () => {
  const { id } = useParams();
  const [attendance, setAttendance] = useState([]);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [employeeName, setEmployeeName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchAttendance();
  }, []);

  useEffect(() => {
    filterAttendance();
  }, [startDate, endDate, attendance]);

  const fetchAttendance = async () => {
    try {
      const response = await getEmployeeAttendance(id);
      const sortedAttendance = response.data.attendance.sort((a, b) => new Date(b.date) - new Date(a.date));
      setAttendance(sortedAttendance);
      setFilteredAttendance(sortedAttendance);
      setEmployeeName(response.data.name);
    } catch (error) {
      console.error('Failed to fetch attendance:', error);
    }
  };

  const filterAttendance = () => {
    const filtered = attendance.filter((record) => {
      const recordDate = new Date(record.date);
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      if (start && end) {
        return recordDate >= start && recordDate <= end;
      } else if (start) {
        return recordDate >= start;
      } else if (end) {
        return recordDate <= end;
      } else {
        return true; // No filter applied, show all
      }
    });
    setFilteredAttendance(filtered);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  return (
    <div className="container mx-auto px-4 py-5">
      {/* Sticky Header for Employee Name */}
      <div className="sticky top-0 bg-white z-10 py-2 border-b border-gray-300">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">Attendance for {employeeName}</h1>
        <button
          onClick={() => navigate('/employee-attendance-dashboard')}
          className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Back to Dashboard
        </button>
      </div>

      {/* Date Filter */}
      <div className="mb-6 mt-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Filter by Date:
        </label>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="p-2 border rounded-lg"
            placeholder="Start Date"
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="p-2 border rounded-lg"
            placeholder="End Date"
          />
        </div>
      </div>

      {/* Attendance List */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredAttendance.length > 0 ? (
          filteredAttendance.map((record, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-4 hover:bg-gray-100">
              <p className="text-gray-800">
                <strong>Date:</strong> {formatDate(record.date)}
              </p>
              <p className="text-gray-800">
                <strong>Check-In Time:</strong> {record.enteredTime}
              </p>
              <p className="text-gray-800">
                <strong>Check-Out Time:</strong> {record.goesTime}
              </p>
              <p className="text-gray-800">
                <strong>Task:</strong> {record.task}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No records available for the selected date range.</p>
        )}
      </div>
    </div>
  );
};

export default ViewAttendance;
