import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPhase, updatePhase } from '../utils/phaseApi';
import { getAuth } from 'firebase/auth';
import AddMaterialModal from './AddMaterialModal';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const MaterialsPage = () => {
  const { phaseId } = useParams();
  const navigate = useNavigate();
  const [phase, setPhase] = useState(null);
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [materialToDelete, setMaterialToDelete] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    fetchPhase();
    fetchUserRole();
  }, [phaseId]);

  const fetchPhase = async () => {
    try {
      const data = await getPhase(phaseId);
      setPhase(data);
    } catch (error) {
      console.error('Error fetching phase:', error);
    }
  };

  const fetchUserRole = async () => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdTokenResult();
      setUserRole(token.claims.role);
    }
  };

  const handleAddMaterial = async (newMaterial) => {
    const updatedMaterials = [...phase.estimatedMaterials, newMaterial];
    const updatedPhase = { ...phase, estimatedMaterials: updatedMaterials };

    try {
      await updatePhase(phase._id, updatedPhase);
      setPhase(updatedPhase);
      setShowAddMaterialModal(false);
    } catch (error) {
      console.error('Error updating phase:', error);
    }
  };

  const handleDeleteMaterial = (material) => {
    setMaterialToDelete(material);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteMaterial = async () => {
    const updatedMaterials = phase.estimatedMaterials.filter(
      (material) => material !== materialToDelete
    );
    const updatedPhase = { ...phase, estimatedMaterials: updatedMaterials };

    try {
      await updatePhase(phase._id, updatedPhase);
      setPhase(updatedPhase);
      setShowDeleteConfirmation(false);
      setMaterialToDelete(null);
    } catch (error) {
      console.error('Error updating phase:', error);
    }
  };

  const generateReport = () => {
    const headers = ["Material Name", "Material Type", "Quantity Needed"];
    const data = phase.estimatedMaterials.map(material => [
      material.materialName,
      material.materialType,
      material.quantityNeeded
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Materials Report");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `Materials_Report_${phase.name}.xlsx`);
  };

  if (!phase) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <button
            className="bg-gray-400 text-gray-800 px-3 py-1 rounded hover:bg-gray-400 mr-4"
            onClick={() => navigate('/')}
          >
            Back
          </button>
          <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">
            Materials for {phase.name}
          </h2>
        </div>
        <div className="flex space-x-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => setShowAddMaterialModal(true)}
          >
            Add Material
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={generateReport}
          >
            Generate Report
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
        {phase.estimatedMaterials.map((material, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-md p-4 sm:p-6 hover:bg-gray-100"
          >
            <h3 className="text-lg font-semibold text-gray-800">{material.materialName}</h3>
            <p className="text-gray-600 mt-2"><strong>Type:</strong> {material.materialType}</p>
            <p className="text-gray-600 mt-2"><strong>Quantity Needed:</strong> {material.quantityNeeded}</p>
            {userRole === 'superadmin' && (
              <button
                className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={() => handleDeleteMaterial(material)}
              >
                Delete
              </button>
            )}
          </div>
        ))}
      </div>
      {showAddMaterialModal && (
        <AddMaterialModal
          onClose={() => setShowAddMaterialModal(false)}
          onAddMaterial={handleAddMaterial}
        />
      )}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 max-w-md w-full rounded-lg">
            <p className="mb-4 text-black">Are you sure you want to delete this material?</p>
            <div className="flex justify-end">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="mr-4 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
              >
                No
              </button>
              <button
                onClick={confirmDeleteMaterial}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaterialsPage;
