import React, { useState } from 'react';
import { materialsList, materialTypes, quantityTypes } from '../utils/constants';

const AddMaterialModal = ({ onClose, onAddMaterial }) => {
  const [materialName, setMaterialName] = useState('');
  const [materialType, setMaterialType] = useState('');
  const [quantityNeeded, setQuantityNeeded] = useState('');
  const [quantityType, setQuantityType] = useState('');
  const [errors, setErrors] = useState({});

  const handleAdd = () => {
    const newErrors = validateFields();
    if (Object.keys(newErrors).length === 0) {
      onAddMaterial({
        materialName,
        materialType,
        quantityNeeded: `${quantityNeeded} ${quantityType}`,
      });
      onClose();
    } else {
      setErrors(newErrors);
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!materialName) newErrors.materialName = 'Material Name is required';
    if (materialName === 'Miscellaneous Items' && !materialType) {
      newErrors.materialType = 'Material Type is required';
    }
    if (!quantityNeeded || isNaN(quantityNeeded) || quantityNeeded <= 0) {
      newErrors.quantityNeeded = 'Valid Quantity Needed is required';
    }
    if (materialName === 'Miscellaneous Items' && !quantityType) {
      newErrors.quantityType = 'Quantity Type is required';
    }
    return newErrors;
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Add Material</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Material Name</label>
          <select
            value={materialName}
            onChange={(e) => setMaterialName(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select Material</option>
            {materialsList.map((material) => (
              <option key={material} value={material}>
                {material}
              </option>
            ))}
          </select>
          {errors.materialName && <p className="text-red-500 text-sm">{errors.materialName}</p>}
        </div>
        {materialName && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700">Material Type</label>
              {materialName === 'Miscellaneous Items' ? (
                <input
                  type="text"
                  value={materialType}
                  onChange={(e) => setMaterialType(e.target.value)}
                  placeholder="Enter Material Type"
                  className="w-full p-2 border rounded"
                />
              ) : (
                <select
                  value={materialType}
                  onChange={(e) => setMaterialType(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select Type</option>
                  {materialTypes[materialName]?.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              )}
              {errors.materialType && <p className="text-red-500 text-sm">{errors.materialType}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Quantity Needed</label>
              <input
                type="text"
                value={quantityNeeded}
                onChange={(e) => setQuantityNeeded(e.target.value)}
                placeholder="Enter Quantity"
                className="w-full p-2 border rounded"
              />
              {errors.quantityNeeded && <p className="text-red-500 text-sm">{errors.quantityNeeded}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Quantity Type</label>
              {materialName === 'Miscellaneous Items' ? (
                <input
                  type="text"
                  value={quantityType}
                  onChange={(e) => setQuantityType(e.target.value)}
                  placeholder="Enter Quantity Type"
                  className="w-full p-2 border rounded"
                />
              ) : (
                <select
                  value={quantityType}
                  onChange={(e) => setQuantityType(e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select Quantity Type</option>
                  {quantityTypes[materialName]?.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              )}
              {errors.quantityType && <p className="text-red-500 text-sm">{errors.quantityType}</p>}
            </div>
          </>
        )}
        <div className="flex justify-end">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleAdd}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMaterialModal;
