import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addEmployee } from '../utils/employeeApi';

const AddEmployee = () => {
  const [formData, setFormData] = useState({
    name: '',
    idNumber: '',
    employeeType: 'Site Manager', // Default value
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.idNumber) newErrors.idNumber = 'ID Number is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length === 0) {
      try {
        await addEmployee(formData);
        navigate('/workerdashboard');
      } catch (error) {
        console.error('Error adding employee:', error);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="container mx-auto px-4 py-5">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Add Employee</h1>
      <form onSubmit={handleSubmit} className="bg-gray-100 p-8 shadow rounded-lg">
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
          </div>
          <div>
            <label htmlFor="idNumber" className="block mb-2 text-sm font-medium text-gray-900">
              ID Number
            </label>
            <input
              type="text"
              id="idNumber"
              name="idNumber"
              value={formData.idNumber}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
            {errors.idNumber && <p className="text-red-500 text-sm">{errors.idNumber}</p>}
          </div>
          <div>
            <label htmlFor="employeeType" className="block mb-2 text-sm font-medium text-gray-900">
              Employee Type
            </label>
            <select
              id="employeeType"
              name="employeeType"
              value={formData.employeeType}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            >
              <option value="Site Manager">Site Manager</option>
              <option value="Project Manager">Project Manager</option>
              <option value="Construction Supervisor">Construction Supervisor</option>
              <option value="Foreman">Foreman</option>
              <option value="General Laborer">General Laborer</option>
              <option value="Carpenter">Carpenter</option>
              <option value="Electrician">Electrician</option>
              <option value="Plumber">Plumber</option>
              <option value="Mason">Mason</option>
              <option value="Steelworker">Steelworker</option>
              <option value="Equipment Operator">Equipment Operator</option>
              <option value="Safety Officer">Safety Officer</option>
              <option value="Surveyor">Surveyor</option>
              <option value="Architect">Architect</option>
              <option value="Civil Engineer">Civil Engineer</option>
              <option value="Structural Engineer">Structural Engineer</option>
              <option value="Mechanical Engineer">Mechanical Engineer</option>
              <option value="HVAC Technician">HVAC Technician</option>
              <option value="Painter">Painter</option>
              <option value="Welder">Welder</option>
            </select>
          </div>
        </div>
        <button type="submit" className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddEmployee;
