import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';

// Create the context
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  // Function to handle logout
  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  useEffect(() => {
    // Set session persistence to session only
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // Subscribe to authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setCurrentUser(user);
        });

        // Unsubscribe from the listener when component unmounts
        return unsubscribe;
      })
      .catch((error) => {
        console.error('Error setting session persistence:', error.message);
      });
  }, []);

  return (
    // Provide the currentUser state and logout function to the context
    <AuthContext.Provider value={{ currentUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
