import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEmployees } from '../utils/employeeApi';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

const AttendanceDashboard = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await getEmployees();
      setEmployees(response.data);
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  const handleAddAttendance = (id) => {
    navigate(`/add-attendance/${id}`);
  };

  const handleViewAttendance = (id) => {
    navigate(`/view-attendance/${id}`);
  };

  const handleAddEmployee = () => {
    navigate('/add-employee');
  };

  const handleGenerateReport = () => {
    setShowModal(true); // Show modal for selecting contractor, task, and date range
  };

  const generateExcelReport = () => {
    const filteredData = employees.flatMap((employee) =>
      employee.attendance
        .filter((record) => {
          // Apply filtering based on contractor, task, and date range
          const contractorMatch = selectedContractor ? record.contractor === selectedContractor : true;
          const taskMatch = selectedTask ? record.task.startsWith(selectedTask) : true;
          const dateMatch =
            (!startDate || new Date(record.date) >= new Date(startDate)) &&
            (!endDate || new Date(record.date) <= new Date(endDate));

          return contractorMatch && taskMatch && dateMatch;
        })
        .map((record) => ({
          name: employee.name,
          date: new Date(record.date).toLocaleDateString(),
          enteredTime: record.enteredTime,
          goesTime: record.goesTime,
          task: record.task,
          contractor: record.contractor,
        }))
    );

    if (filteredData.length === 0) {
      // Trigger SweetAlert2 popup
      Swal.fire({
        icon: 'warning',
        title: 'No Data to Export',
        text: 'Please adjust your filters or try again with different criteria.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Okay',
      });
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance Report');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'AttendanceReport.xlsx');

    setShowModal(false); // Close modal after generating report
  };

  return (
    <div className="container mx-auto px-4 py-5">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6 text-center md:text-left">Employee Attendance Dashboard</h1>
      <div className="flex justify-center md:justify-start mb-4 space-x-2">
        <button
          onClick={handleAddEmployee}
          className="text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Add Employee
        </button>
        <button
          onClick={handleGenerateReport}
          className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Generate Report
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {employees.map((employee) => (
          <div key={employee._id} className="bg-white p-6 rounded-lg shadow-lg bg-gradient-to-r from-blue-50 to-blue-100">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{employee.name}</h3>
            <p className="text-gray-600 mb-1"><strong>ID Number:</strong> {employee.idNumber}</p>
            <p className="text-gray-600 mb-1"><strong>Employee Type:</strong> {employee.employeeType}</p>
            <div className="flex justify-end mt-4 space-x-2">
              <button
                onClick={() => handleViewAttendance(employee._id)}
                className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 transition duration-300 ease-in-out transform hover:scale-105"
              >
                View Attendance
              </button>
              <button
                onClick={() => handleAddAttendance(employee._id)}
                className="text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Add Attendance
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for selecting contractor, task, and date range */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Generate Report</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Contractor</label>
              <select
                onChange={(e) => setSelectedContractor(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Contractor</option>
                <option value="Contractor Dilan">Contractor Dilan</option>
                <option value="Contractor Thusitha">Contractor Thusitha</option>
                <option value="None">None</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Task</label>
              <select
                onChange={(e) => setSelectedTask(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Task</option>
                <option value="TDS Sheet Task - Construction Work">TDS Sheet Task - Construction Work</option>
                <option value="Out Task">Out Task</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Start Date</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">End Date</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Modal Actions */}
            <div className="flex justify-end">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={generateExcelReport}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Next & Download Report
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttendanceDashboard;
