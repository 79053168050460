// src/config/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyChDo0OVYvFVsSyZUi3tWyVFsfQsXdmiK8",
  authDomain: "ellahotel-55580.firebaseapp.com",
  projectId: "ellahotel-55580",
  storageBucket: "ellahotel-55580.appspot.com",
  messagingSenderId: "664012915535",
  appId: "1:664012915535:web:99789f9e3e7d526c0b3b3a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
