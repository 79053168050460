import React, { useState, useEffect } from 'react';
import { getPhases } from '../utils/phaseApi';

const SelectPhaseModal = ({ onClose, onSelect }) => {
  const [phases, setPhases] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPhases();
  }, []);

  // Fetch phases and filter out closed ones
  const fetchPhases = async () => {
    try {
      const response = await getPhases();
      if (Array.isArray(response)) {
        const openPhases = response.filter(phase => phase.status !== 'closed');
        setPhases(openPhases);
      } else {
        console.error('Fetched data is not an array:', response);
      }
    } catch (error) {
      console.error('Error fetching phases:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Select Phase</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Phase</label>
          <select
            onChange={(e) => onSelect(phases.find(p => p._id === e.target.value))}
            className="w-full p-2 border rounded"
          >
            <option value="">Select Phase</option>
            {phases.map((phase) => (
              <option key={phase._id} value={phase._id}>
                {phase.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectPhaseModal;
