import React, { useState } from 'react';
import useUserRole from '../hooks/useUserRole'; // Adjust the path as needed

const UsageHistoryModal = ({ material, onClose, onDeleteUsage }) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [usageIdToDelete, setUsageIdToDelete] = useState(null);
  const { role } = useUserRole(); // Assuming useUserRole is a hook that provides the user's role

  // Sort history in descending order by usageDate
  const history = (material.usageHistory || []).sort((a, b) => new Date(b.usageDate) - new Date(a.usageDate));
  const isScrollable = history.length > 3; // Determine if scroll is needed

  const confirmDelete = (usageId) => {
    setUsageIdToDelete(usageId);
    setShowConfirmDelete(true);
  };

  const handleDelete = () => {
    onDeleteUsage(usageIdToDelete, material._id);
    setShowConfirmDelete(false);
  };

  // Helper function to format the date in yyyy/mm/dd format
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-CA', options); // 'en-CA' for yyyy/mm/dd
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
        <h2 className="text-xl font-semibold mb-4">
          Usage History for {material.materialName} ({material.materialType})
        </h2>
        <div className={`space-y-4 ${isScrollable ? 'h-96 overflow-y-auto' : ''}`}>
          {history.length > 0 ? (
            history.map((entry, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg hover:bg-gray-200 flex justify-between">
                <div>
                  <p><strong>Date:</strong> {formatDate(entry.usageDate)}</p>
                  <p><strong>Quantity Used:</strong> {entry.usageQuantity} {entry.usageType}</p>
                </div>
                {role === 'superadmin' && (
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded ml-4"
                    onClick={() => confirmDelete(entry._id)}
                  >
                    Delete
                  </button>
                )}
              </div>
            ))
          ) : (
            <p className="text-gray-600 text-center py-4">No usage history available.</p>
          )}
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>

      {showConfirmDelete && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 max-w-md w-full rounded-lg">
            <p className="mb-4 text-black">Are you sure you want to delete this usage entry?</p>
            <div className="flex justify-end">
              <button
                onClick={() => setShowConfirmDelete(false)}
                className="mr-4 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
              >
                No
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsageHistoryModal;
