import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEmployees, deleteEmployee } from '../utils/employeeApi';
import { getAuth } from 'firebase/auth';

const EmployeeDashboard = () => {
  const [employees, setEmployees] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    fetchEmployees();
    fetchUserRole();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await getEmployees();
      setEmployees(response.data);
    } catch (error) {
      console.error('Failed to fetch employees:', error);
    }
  };

  const fetchUserRole = async () => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdTokenResult();
      setUserRole(token.claims.role);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteEmployee(id);
      setEmployees(employees.filter(employee => employee._id !== id));
      setShowConfirm(false);
      setDeleteId(null);
    } catch (error) {
      console.error('Failed to delete employee:', error);
    }
  };

  const confirmDelete = (id) => {
    setShowConfirm(true);
    setDeleteId(id);
  };

  const handleUpdate = (id) => {
    navigate(`/update-employee/${id}`);
  };

  const handleAddEmployee = () => {
    navigate('/add-employee');
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
    setDeleteId(null);
  };

  return (
    <div className="container mx-auto px-4 py-5">
      <h1 className="text-2xl font-semibold text-gray-900 mb-6">Employee Dashboard</h1>
      <button
        onClick={handleAddEmployee}
        className="mb-4 text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Add Employee
      </button>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {employees.map((employee) => (
          <div key={employee._id} className="bg-white rounded-lg shadow-md p-6 hover:bg-gray-100">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">{employee.name}</h2>
            <p className="text-gray-600"><strong>ID Number:</strong> {employee.idNumber}</p>
            <p className="text-gray-600"><strong>Employee Type:</strong> {employee.employeeType}</p>
            <div className="flex justify-end mt-4">
              {userRole === 'superadmin' && (
                <>
                  <button
                    onClick={() => handleUpdate(employee._id)}
                    className="text-blue-500 hover:text-blue-700 font-medium mr-4"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => confirmDelete(employee._id)}
                    className="text-red-500 hover:text-red-700 font-medium"
                  >
                    Delete
                  </button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>

      {showConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <h2 className="text-2xl font-semibold mb-4">Are you sure you want to delete this employee?</h2>
            <div className="flex justify-center">
              <button
                onClick={() => handleDelete(deleteId)}
                className="mr-4 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5"
              >
                Yes
              </button>
              <button
                onClick={handleCancelDelete}
                className="text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeDashboard;
