import React, { useState, useEffect } from 'react';
import { getPhases, updatePhase, deleteUsage } from '../utils/phaseApi';
import SelectPhaseModal from '../components/SelectPhaseModal';
import UsageHistoryModal from '../components/UsageHistoryModal';
import AddUsageModal from '../components/AddUsageModal';

const UsageManagementDashboard = () => {
  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [dropdownSelectedPhase, setDropdownSelectedPhase] = useState(null);
  const [showSelectPhaseModal, setShowSelectPhaseModal] = useState(true);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [showUsageHistoryModal, setShowUsageHistoryModal] = useState(false);
  const [showAddUsageModal, setShowAddUsageModal] = useState(false);

  useEffect(() => {
    fetchPhases();
  }, []);

  const fetchPhases = async () => {
    try {
      const response = await getPhases();
      if (Array.isArray(response)) {
        setPhases(response);
      } else {
        console.error('Fetched data is not an array:', response);
      }
    } catch (error) {
      console.error('Error fetching phases:', error);
    }
  };

  const handleSelectPhase = (phase) => {
    setSelectedPhase(phase);
    setDropdownSelectedPhase(phase);
    setShowSelectPhaseModal(false);
  };

  const handleDropdownSelectPhase = (e) => {
    const selected = phases.find((phase) => phase._id === e.target.value);
    setDropdownSelectedPhase(selected);
    setSelectedPhase(selected);
  };

  const handleMaterialNameClick = (material) => {
    setSelectedMaterial(material);
    setShowUsageHistoryModal(true);
  };

  const handleAddUsageClick = (material) => {
    setSelectedMaterial(material);
    setShowAddUsageModal(true);
  };

  const handleSaveUsage = async (usageRecord) => {
    const updatedPhase = { ...selectedPhase };
    const material = updatedPhase.stockIncome.find(m => m._id === usageRecord.materialId);

    if (!material.usageHistory) {
      material.usageHistory = [];
    }

    const aggregatedMaterials = aggregateMaterials(updatedPhase.stockIncome);
    const aggregatedMaterial = aggregatedMaterials.find(m => m._id === material._id);

    const totalReceived = aggregatedMaterial.receivedQuantity;
    const totalUsage = aggregatedMaterial.usageQuantity + parseFloat(usageRecord.usageQuantity);

    if (totalUsage > totalReceived) {
      alert('Usage quantity exceeds the total received quantity.');
      return;
    }

    material.usageHistory.push(usageRecord);

    try {
      const response = await updatePhase(updatedPhase._id, updatedPhase);
      alert('Usage details saved successfully.');
      setShowAddUsageModal(false);
      setSelectedPhase(updatedPhase);
    } catch (error) {
      console.error('Error updating phase:', error);
    }
  };

  const handleDeleteUsage = async (usageId, materialId) => {
    const updatedPhase = { ...selectedPhase };
    const material = updatedPhase.stockIncome.find(m => m._id === materialId);

    material.usageHistory = material.usageHistory.filter(entry => entry._id !== usageId);

    try {
      await updatePhase(updatedPhase._id, updatedPhase);
      alert('Usage entry deleted successfully.');
      setShowUsageHistoryModal(false);
      setSelectedPhase(updatedPhase);
    } catch (error) {
      console.error('Error deleting usage entry:', error);
    }
  };

  const calculateTotalReceived = (material) => {
    const receivedQuantities = material.receivedHistory?.map(entry => parseFloat(entry.receivedQuantity) || 0) || [];
    return receivedQuantities.reduce((total, qty) => total + qty, 0);
  };

  const calculateTotalUsage = (material) => {
    const usageQuantities = material.usageHistory?.map(entry => parseFloat(entry.usageQuantity) || 0) || [];
    return usageQuantities.reduce((total, qty) => total + qty, 0);
  };

  const aggregateMaterials = (materials) => {
    const aggregated = {};
    materials.forEach((material) => {
      const key = `${material.materialName}-${material.materialType}`;
      if (!aggregated[key]) {
        aggregated[key] = {
          ...material,
          receivedQuantity: calculateTotalReceived(material),
          usageQuantity: calculateTotalUsage(material),
        };
      } else {
        aggregated[key].receivedQuantity += calculateTotalReceived(material);
        aggregated[key].usageQuantity += calculateTotalUsage(material);
      }
    });
    return Object.values(aggregated);
  };

  const aggregatedMaterials = selectedPhase ? aggregateMaterials(selectedPhase.stockIncome) : [];

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-6">
      {showSelectPhaseModal && (
        <SelectPhaseModal
          onClose={() => setShowSelectPhaseModal(false)}
          onSelect={handleSelectPhase}
        />
      )}
      {!showSelectPhaseModal && (
        <>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl md:text-3xl font-semibold text-gray-800">
              Usage Management Dashboard
            </h1>
            <select
              value={dropdownSelectedPhase ? dropdownSelectedPhase._id : ''}
              onChange={handleDropdownSelectPhase}
              className="p-2 border rounded"
              required
            >
              {phases.map((phase) => (
                <option key={phase._id} value={phase._id}>
                  {phase.name}
                </option>
              ))}
            </select>
          </div>
          {selectedPhase && (
            <div className="space-y-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">Materials Usage for {selectedPhase.name}</h2>
              {aggregatedMaterials.map((material, index) => (
                <div
                  key={material._id || index}
                  className="bg-white p-4 rounded-lg shadow-md"
                >
                  <h3 className="text-lg font-medium text-gray-700 mb-2">{material.materialName} ({material.materialType})</h3>
                  <div className="text-sm text-gray-600 space-y-1">
                    <p><strong>Received Quantity:</strong> {material.receivedQuantity}</p>
                    <p><strong>Usage Quantity:</strong> {material.usageQuantity}</p>
                    <p><strong>Remaining Quantity:</strong> {(material.receivedQuantity - material.usageQuantity).toFixed(2)}</p>
                  </div>
                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents card click
                        handleMaterialNameClick(material);
                      }}
                    >
                      View Usage History
                    </button>
                    <button
                      className={`bg-green-500 text-white px-4 py-2 rounded ${material.receivedQuantity - material.usageQuantity <= 0 ? 'cursor-not-allowed' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents card click
                        handleAddUsageClick(material);
                      }}
                      disabled={material.receivedQuantity - material.usageQuantity <= 0}
                    >
                      Add Usage
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          {showUsageHistoryModal && selectedMaterial && (
            <UsageHistoryModal
              material={selectedMaterial}
              onClose={() => setShowUsageHistoryModal(false)}
              onDeleteUsage={handleDeleteUsage}
            />
          )}
          {showAddUsageModal && selectedMaterial && (
            <AddUsageModal
              material={selectedMaterial}
              onClose={() => setShowAddUsageModal(false)}
              onSave={handleSaveUsage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UsageManagementDashboard;
