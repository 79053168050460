// supplierApi.js

import axios from './axios'; // Assuming you have a configured axios instance

const API_BASE_URL = '/suppliers'; // Base URL for the supplier API

// Function to create a new supplier
export const createSupplier = async (supplierData) => {
  try {
    const response = await axios.post(API_BASE_URL, supplierData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network Error');
  }
};

// Function to get all suppliers
export const getAllSuppliers = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network Error');
  }
};

// Function to delete a supplier by ID
export const deleteSupplier = async (id) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network Error');
  }
};
