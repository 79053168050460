import React, { useState, useEffect } from 'react';
import { quantityTypes } from '../utils/constants';

const ReceiveMaterialModal = ({ material, onClose, onSave, calculateTotalReceived }) => {
  const [receivedDate, setReceivedDate] = useState('');
  const [receivedTime, setReceivedTime] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [receivedQuantity, setReceivedQuantity] = useState('');
  const [materialAcceptedBy, setMaterialAcceptedBy] = useState('');
  const [received, setReceived] = useState(false);
  const [remainingQuantity, setRemainingQuantity] = useState(0);
  const [selectedQuantityType, setSelectedQuantityType] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const totalReceived = calculateTotalReceived(material);
    setRemainingQuantity(parseFloat(material.orderQuantity) - totalReceived);
  }, [material, calculateTotalReceived]);

  const handleSave = () => {
    if (isNaN(receivedQuantity) || receivedQuantity <= 0) {
      alert('Received quantity must be a positive number.');
      return;
    }

    if (selectedQuantityType !== material.quantityType) {
      setError(`Quantity type must be ${material.quantityType}`);
      return;
    }

    const totalReceived = calculateTotalReceived(material) + parseFloat(receivedQuantity);

    if (totalReceived > parseFloat(material.orderQuantity)) {
      alert('Received quantity exceeds the ordered quantity.');
      return;
    }

    const formattedReceivedQuantity = `${receivedQuantity} ${selectedQuantityType}`;

    const updatedMaterial = {
      ...material,
      receivedDate,
      receivedTime,
      vehicleNumber,
      receivedQuantity,
      materialAcceptedBy,
      received: totalReceived === parseFloat(material.orderQuantity),
    };
    onSave(updatedMaterial);
  };

  const handleQuantityTypeChange = (e) => {
    setSelectedQuantityType(e.target.value);
    if (e.target.value !== material.quantityType) {
      setError(`Quantity type must be ${material.quantityType}`);
    } else {
      setError('');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
        <h2 className="text-xl font-semibold mb-4">Receive Material: {material.materialName}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label className="block text-gray-700">Received Date</label>
            <input
              type="date"
              value={receivedDate}
              onChange={(e) => setReceivedDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Received Time</label>
            <input
              type="time"
              value={receivedTime}
              onChange={(e) => setReceivedTime(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Vehicle Number</label>
            <input
              type="text"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Received Quantity</label>
            <input
              type="text"
              value={receivedQuantity}
              onChange={(e) => setReceivedQuantity(e.target.value)}
              placeholder={`Remaining: ${remainingQuantity}`}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Quantity Type</label>
            <select
              value={selectedQuantityType}
              onChange={handleQuantityTypeChange}
              className="w-full p-2 border rounded"
            >
              <option value="">Select Quantity Type</option>
              {quantityTypes[material.materialName].map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Material Accepted By</label>
            <input
              type="text"
              value={materialAcceptedBy}
              onChange={(e) => setMaterialAcceptedBy(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Received</label>
            <input
              type="checkbox"
              checked={received}
              onChange={(e) => setReceived(e.target.checked)}
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleSave}
          >
            Receive
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceiveMaterialModal;
