// src/components/EstimationDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getPhases, deletePhase } from '../utils/phaseApi';
import AddPhaseModal from '../components/AddPhaseModal';
import AddMaterials from '../components/AddMaterials';
import useUserRole from '../hooks/useUserRole';

const EstimationDashboard = () => {
  const [phases, setPhases] = useState([]);
  const [showAddPhaseModal, setShowAddPhaseModal] = useState(false);
  const [currentPhase, setCurrentPhase] = useState(null);
  const [phaseToDelete, setPhaseToDelete] = useState(null);
  const navigate = useNavigate();
  const { role, loading, error } = useUserRole();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        fetchPhases();
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchPhases = async () => {
    const data = await getPhases();
    if (Array.isArray(data)) {
      setPhases(data);
    }
  };

  const handleAddPhase = (newPhase) => {
    setCurrentPhase(newPhase);
    setShowAddPhaseModal(false);
  };

  const handleFinish = () => {
    setCurrentPhase(null);
    fetchPhases();
  };

  const handlePhaseClick = (phaseId) => {
    navigate(`/materials/${phaseId}`);
  };

  const handleDeletePhase = async () => {
    if (phaseToDelete) {
      try {
        await deletePhase(phaseToDelete._id);
        setPhaseToDelete(null);
        fetchPhases();
      } catch (error) {
        console.error('Error deleting phase:', error);
      }
    }
  };

  const goToDashboard = () => {
    navigate('/phasedashboard'); // Add the route for the dashboard
  };

  if (!isAuthenticated) {
    return <div>Please log in to view this content.</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">Estimation Dashboard</h1>
        <div className="flex space-x-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => setShowAddPhaseModal(true)}
          >
            Add Phase
          </button>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            onClick={goToDashboard}
          >
            Meterial Dashboard
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {phases.map((phase) => (
          <div
            key={phase._id}
            className="bg-white rounded-lg shadow-md p-6 hover:bg-gray-100 cursor-pointer"
            onClick={() => handlePhaseClick(phase._id)}
          >
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-800">{phase.name}</h2>
              {role === 'superadmin' && (
                <button
                  className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPhaseToDelete(phase);
                  }}
                >
                  Delete
                </button>
              )}
            </div>
            <p className="text-gray-600 mt-4">{new Date(phase.createdAt).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
      {showAddPhaseModal && (
        <AddPhaseModal
          onClose={() => setShowAddPhaseModal(false)}
          onNext={handleAddPhase}
        />
      )}
      {currentPhase && (
        <AddMaterials phase={currentPhase} onFinish={handleFinish} />
      )}
      {phaseToDelete && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 max-w-md w-full rounded-lg">
            <p className="mb-4 text-black">Are you sure you want to delete this phase?</p>
            <div className="flex justify-end">
              <button
                onClick={() => setPhaseToDelete(null)}
                className="mr-4 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
              >
                No
              </button>
              <button
                onClick={handleDeletePhase}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EstimationDashboard;
