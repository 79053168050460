import React, { useState, useEffect } from 'react';
import { getPhases, updatePhase } from '../utils/phaseApi';
import { getAllSuppliers, createSupplier } from '../utils/supplierApi';
import SelectPhaseModal from '../components/SelectPhaseModal';
import OrderHistoryModal from '../components/OrderHistoryModal';
import { getQuantityTypes } from '../utils/constants';

const OrderManagementDashboard = () => {
  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [dropdownSelectedPhase, setDropdownSelectedPhase] = useState(null);
  const [showSelectPhaseModal, setShowSelectPhaseModal] = useState(true);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [showOrderHistoryModal, setShowOrderHistoryModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [newSupplier, setNewSupplier] = useState('');

  useEffect(() => {
    fetchPhases();
    fetchSuppliers();
  }, []);

  const fetchPhases = async () => {
    try {
      const response = await getPhases();
      if (Array.isArray(response)) {
        setPhases(response);
      } else {
        console.error('Fetched data is not an array:', response);
      }
    } catch (error) {
      console.error('Error fetching phases:', error);
    }
  };

  const fetchSuppliers = async () => {
    try {
      const response = await getAllSuppliers();
      if (Array.isArray(response)) {
        setSuppliers(response);
      } else {
        console.error('Fetched suppliers data is not an array:', response);
      }
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };

  const handleSelectPhase = (phase) => {
    setSelectedPhase(phase);
    setDropdownSelectedPhase(phase);
    setShowSelectPhaseModal(false);
  };

  const handleDropdownSelectPhase = (e) => {
    const selected = phases.find((phase) => phase._id === e.target.value);
    setDropdownSelectedPhase(selected);
    setSelectedPhase(selected);
  };

  const handleMaterialNameClick = (material) => {
    setSelectedMaterial({
      ...material,
      orderHistory: selectedPhase.orderedMaterials.filter(
        (m) => m._id === material._id
      ),
    });
    setShowOrderHistoryModal(true);
  };

  const validateMaterial = (material) => {
    const newErrors = {};

    if (!material.materialName) newErrors.materialName = 'Material Name is required';
    if (!material.materialType) newErrors.materialType = 'Material Type is required';
    if (!material.supplier) newErrors.supplier = 'Supplier is required';
    if (!material.supplierContact) newErrors.supplierContact = 'Supplier Contact is required';
    if (!material.orderedDate) newErrors.orderedDate = 'Ordered Date is required';
    if (!material.invoiceNumber) newErrors.invoiceNumber = 'Invoice Number is required';
    if (!material.amount || isNaN(material.amount) || parseFloat(material.amount) <= 0) {
      newErrors.amount = 'Valid Amount is required';
    }
    if (!material.orderQuantity || isNaN(material.orderQuantity) || parseFloat(material.orderQuantity) <= 0) {
      newErrors.orderQuantity = 'Valid Order Quantity is required';
    }
    if (!material.quantityType) newErrors.quantityType = 'Quantity Type is required';
    if (!material.unitPrice || isNaN(material.unitPrice) || parseFloat(material.unitPrice) <= 0) {
      newErrors.unitPrice = 'Valid Unit Price is required';
    }
    if (!material.deliveryCharge || isNaN(material.deliveryCharge) || parseFloat(material.deliveryCharge) <= 0) {
      newErrors.deliveryCharge = 'Valid Delivery Charge is required';
    }

    return newErrors;
  };

  const handleAddToOrderedMaterials = async (index) => {
    const updatedMaterials = [...selectedPhase.estimatedMaterials];
    const material = updatedMaterials[index];
    const newErrors = validateMaterial(material);

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const totalOrdered = calculateTotalOrdered(material) + parseFloat(material.orderQuantity);
    const quantityNeeded = parseFloat(material.quantityNeeded);

    if (totalOrdered > quantityNeeded) {
      alert('Order quantity exceeds the quantity needed.');
      return;
    }

    // Handle new supplier creation if the supplier is not from the dropdown
    let supplierId = suppliers.find(s => s.supplier === material.supplier)?._id;
    if (!supplierId) {
      try {
        const newSupplier = await createSupplier({
          supplier: material.supplier,
          supplierContact: material.supplierContact,
        });
        supplierId = newSupplier._id;
        fetchSuppliers(); // Refresh the supplier list
      } catch (error) {
        console.error('Error creating supplier:', error);
        return;
      }
    }

    const orderedMaterial = {
      materialName: material.materialName,
      materialType: material.materialType,
      supplier: material.supplier,
      supplierContact: material.supplierContact,
      orderedDate: material.orderedDate ? new Date(material.orderedDate).toISOString() : null,
      invoiceNumber: material.invoiceNumber,
      amount: `RS ${material.amount}`,
      ordered: true,
      orderQuantity: `${material.orderQuantity} ${material.quantityType}`,
      quantityType: material.quantityType,
      unitPrice: `RS ${material.unitPrice}`,
      deliveryCharge: `RS ${material.deliveryCharge}`,
      orderPieces: calculateOrderPieces(material),
      _id: material._id || `${material.materialName}-${material.materialType}-${Date.now()}`,
      supplierId, // Store the supplier ID
    };

    const stockIncomeMaterial = {
      materialName: material.materialName,
      materialType: material.materialType,
      supplier: material.supplier,
      supplierContact: material.supplierContact,
      orderQuantity: material.orderQuantity,
      orderQuantitywithtype: `${material.orderQuantity} ${material.quantityType}`,
      quantityType: material.quantityType,
      unitPrice: material.unitPrice,
      deliveryCharge: material.deliveryCharge,
      orderPieces: calculateOrderPieces(material),
      receivedQuantity: '',
      received: false,
      materialAcceptedBy: '',
      receivedDate: '',
      receivedTime: '',
      vehicleNumber: '',
      orderedDate: material.orderedDate ? new Date(material.orderedDate).toISOString() : null,
    };

    const updatedOrderedMaterials = [...selectedPhase.orderedMaterials, orderedMaterial];
    const updatedStockIncome = [...selectedPhase.stockIncome, stockIncomeMaterial];
    const updatedPhase = {
      ...selectedPhase,
      orderedMaterials: updatedOrderedMaterials,
      stockIncome: updatedStockIncome,
      estimatedMaterials: updatedMaterials.map((mat, idx) =>
        idx === index ? { ...mat, orderQuantity: '', quantityType: '' } : mat
      ),
    };

    setSelectedPhase(updatedPhase);
    setErrors({});

    await handleUpdatePhase(updatedPhase);
  };

  const handleUpdatePhase = async (updatedPhase) => {
    try {
      await updatePhase(updatedPhase._id, updatedPhase);
      alert('Order and stock income details saved successfully.');
    } catch (error) {
      console.error('Error updating phase:', error);
    }
  };

  const calculateTotalOrdered = (material) => {
    const orderedMaterials = selectedPhase.orderedMaterials.filter(
      (m) => m._id === material._id
    );
    const orderedQuantities = orderedMaterials.map((m) => parseFloat(m.orderQuantity) || 0);
    return orderedQuantities.reduce((total, qty) => total + qty, 0);
  };

  const calculateOrderPieces = (material) => {
    let orderQuantity = parseFloat(material.orderQuantity);
    let pieces = 0;

    if (material.materialName === 'Steel') {
      // Convert tons to kilograms if necessary
      if (material.quantityType === 'Tons') {
        orderQuantity = orderQuantity * 1000;
      }

      if (material.quantityType === 'Kg' || material.quantityType === 'Tons') {
        switch (material.materialType) {
          case 'T-10':
            pieces = orderQuantity / 3.6195;
            break;
          case 'T-12':
            pieces = orderQuantity / 5.3334;
            break;
          case 'T-16':
            pieces = orderQuantity / 9.5000;
            break;
          case 'T-20':
            pieces = orderQuantity / 11.5000;
            break;
          default:
            pieces = 0;
        }
        // Round the pieces to the nearest whole number
        return Math.round(pieces).toString();
      } else {
        // Return the entered value for other quantity types
        return material.orderQuantity;
      }
    }

    // Return the entered value if material is not Steel
    return material.orderQuantity;
  };

  const handleInputChange = (index, field, value) => {
    const updatedMaterials = [...selectedPhase.estimatedMaterials];
    updatedMaterials[index] = { ...updatedMaterials[index], [field]: value };

    // Auto-fill supplier contact if supplier is selected from the dropdown
    if (field === 'supplier') {
      const selectedSupplier = suppliers.find((s) => s.supplier === value);
      if (selectedSupplier) {
        updatedMaterials[index].supplierContact = selectedSupplier.supplierContact;
      }
    }

    setSelectedPhase({ ...selectedPhase, estimatedMaterials: updatedMaterials });
  };

  const isQuantityTypeMismatch = (material) => {
    const quantityTypeNeeded = material.quantityNeeded.split(' ')[1];
    return material.quantityType && material.quantityType !== quantityTypeNeeded;
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-6">
      {showSelectPhaseModal && (
        <SelectPhaseModal
          onClose={() => setShowSelectPhaseModal(false)}
          onSelect={handleSelectPhase}
        />
      )}
      {!showSelectPhaseModal && (
        <>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl md:text-3xl font-semibold text-gray-800">
              Order Management Dashboard
            </h1>
            <select
              value={dropdownSelectedPhase ? dropdownSelectedPhase._id : ''}
              onChange={handleDropdownSelectPhase}
              className="p-2 border rounded"
              required
            >
              {phases.map((phase) => (
                <option key={phase._id} value={phase._id}>
                  {phase.name}
                </option>
              ))}
            </select>
          </div>
          {selectedPhase && (
            <div className="space-y-6">
              <h2 className="text-xl md:text-2xl font-semibold mb-2 md:mb-4">
                Ordered Materials for {selectedPhase.name}
              </h2>
              {selectedPhase.estimatedMaterials.map((material, index) => {
                const remainingQuantity = parseFloat(material.quantityNeeded) - calculateTotalOrdered(material);
                const isOrdered = material.ordered || remainingQuantity === 0;
                const isDisabled = remainingQuantity === 0 || material.orderQuantity === material.quantityNeeded;
                return (
                  <div
                    key={material._id || `${material.materialName}-${material.materialType}-${index}`}
                    className={`p-4 rounded-lg shadow-md ${isOrdered ? 'bg-gray-300' : 'bg-white cursor-pointer'}`}
                  >
                    <h3 className="text-lg font-medium text-gray-700 mb-2">{material.materialName}</h3>
                    <div className="text-sm text-gray-600 space-y-1">
                      <p><strong>Material Type:</strong> {material.materialType}</p>
                      <p><strong>Quantity Needed:</strong> {material.quantityNeeded}</p>
                      <div>
                        <p><strong>Supplier:</strong></p>
                        <input
                          type="text"
                          value={material.supplier || ''}
                          onChange={(e) => handleInputChange(index, 'supplier', e.target.value)}
                          list="suppliers"
                          className="w-full p-2 border rounded mt-1"
                          disabled={isOrdered}
                          required
                        />
                        <datalist id="suppliers">
                          {suppliers.map((supplier) => (
                            <option key={supplier._id} value={supplier.supplier}>
                              {supplier.supplier}
                            </option>
                          ))}
                        </datalist>
                        {errors.supplier && <p className="text-red-500 text-sm">{errors.supplier}</p>}
                      </div>
                      <div>
                        <p><strong>Supplier Contact:</strong></p>
                        <input
                          type="text"
                          value={material.supplierContact || ''}
                          onChange={(e) => handleInputChange(index, 'supplierContact', e.target.value)}
                          className="w-full p-2 border rounded mt-1"
                          disabled={isOrdered}
                          required
                        />
                        {errors.supplierContact && <p className="text-red-500 text-sm">{errors.supplierContact}</p>}
                      </div>
                      <div>
                        <p><strong>Ordered Date:</strong></p>
                        <input
                          type="date"
                          value={material.orderedDate ? new Date(material.orderedDate).toISOString().split('T')[0] : ''}
                          onChange={(e) => handleInputChange(index, 'orderedDate', e.target.value)}
                          className="w-full p-2 border rounded mt-1"
                          disabled={isOrdered}
                          required
                        />
                        {errors.orderedDate && <p className="text-red-500 text-sm">{errors.orderedDate}</p>}
                      </div>
                      <div>
                        <p><strong>Invoice Number:</strong></p>
                        <input
                          type="text"
                          value={material.invoiceNumber || ''}
                          onChange={(e) => handleInputChange(index, 'invoiceNumber', e.target.value)}
                          className="w-full p-2 border rounded mt-1"
                          disabled={isOrdered}
                          required
                        />
                        {errors.invoiceNumber && <p className="text-red-500 text-sm">{errors.invoiceNumber}</p>}
                      </div>
                      <div>
                        <p><strong>Amount(Rs):</strong></p>
                        <input
                          type="number"
                          value={material.amount || ''}
                          onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                          className="w-full p-2 border rounded mt-1"
                          disabled={isOrdered}
                          required
                        />
                        {errors.amount && <p className="text-red-500 text-sm">{errors.amount}</p>}
                      </div>
                      <div>
                        <p><strong>Unit Price(Rs):</strong></p>
                        <input
                          type="number"
                          value={material.unitPrice || ''}
                          onChange={(e) => handleInputChange(index, 'unitPrice', e.target.value)}
                          className="w-full p-2 border rounded mt-1"
                          disabled={isOrdered}
                          required
                        />
                        {errors.unitPrice && <p className="text-red-500 text-sm">{errors.unitPrice}</p>}
                      </div>
                      <div>
                        <p><strong>Delivery Charge(Rs):</strong></p>
                        <input
                          value={material.deliveryCharge || ''}
                          onChange={(e) => handleInputChange(index, 'deliveryCharge', e.target.value)}
                          className="w-full p-2 border rounded mt-1"
                          disabled={isOrdered}
                          required
                        />
                        {errors.deliveryCharge && <p className="text-red-500 text-sm">{errors.deliveryCharge}</p>}
                      </div>
                      <div>
                        <p><strong>Order Quantity:</strong></p>
                        <input
                          type="text"
                          value={material.orderQuantity || ''}
                          onChange={(e) => handleInputChange(index, 'orderQuantity', e.target.value)}
                          placeholder={`Remaining: ${remainingQuantity}`}
                          className="w-full p-2 border rounded mt-1"
                          disabled={isDisabled}
                          required
                        />
                        {errors.orderQuantity && <p className="text-red-500 text-sm">{errors.orderQuantity}</p>}
                      </div>
                      <div>
                        <p><strong>Quantity Type:</strong></p>
                        <select
                          value={material.quantityType || ''}
                          onChange={(e) => handleInputChange(index, 'quantityType', e.target.value)}
                          className="w-full p-2 border rounded mt-1"
                          disabled={isOrdered}
                          required
                        >
                          <option value="">Select Quantity Type</option>
                          {getQuantityTypes(material.materialName).map((option) => (
                            <option key={option} value={option}>{option}</option>
                          ))}
                        </select>
                        {errors.quantityType && <p className="text-red-500 text-sm">{errors.quantityType}</p>}
                        {isQuantityTypeMismatch(material) && (
                          <p className="text-red-500 text-sm">Quantity Type does not match with Quantity Needed's type.</p>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                      <button
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={() => handleMaterialNameClick(material)}
                      >
                        View History
                      </button>
                      {isOrdered ? (
                        <button
                          className="bg-green-500 text-white px-4 py-2 rounded"
                          disabled
                        >
                          Ordered
                        </button>
                      ) : (
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded"
                          onClick={() => handleAddToOrderedMaterials(index)}
                        >
                          Add Order
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {showOrderHistoryModal && selectedMaterial && (
            <OrderHistoryModal
              material={selectedMaterial}
              onClose={() => setShowOrderHistoryModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default OrderManagementDashboard;
