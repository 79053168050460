import React, { useState } from 'react';
import { materialsList, materialTypes, quantityTypes } from '../utils/constants';
import { updatePhase } from '../utils/phaseApi';

const AddMaterials = ({ phase, onFinish }) => {
  const [materials, setMaterials] = useState([]);
  const [materialName, setMaterialName] = useState('');
  const [materialType, setMaterialType] = useState('');
  const [quantityNeeded, setQuantityNeeded] = useState('');
  const [quantityType, setQuantityType] = useState('');
  const [errors, setErrors] = useState({});

  const handleAddMaterial = () => {
    const newErrors = validateFields();
    if (Object.keys(newErrors).length === 0) {
      const newMaterial = { 
        materialName, 
        materialType, 
        quantityNeeded: `${quantityNeeded} ${quantityType}` 
      };
      setMaterials([...materials, newMaterial]);
      setMaterialName('');
      setMaterialType('');
      setQuantityNeeded('');
      setQuantityType('');
      setErrors({});
    } else {
      setErrors(newErrors);
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!materialName) newErrors.materialName = 'Material Name is required';
    if (materialName === 'Miscellaneous Items' && !materialType) {
      newErrors.materialType = 'Material Type is required';
    }
    if (!quantityNeeded || isNaN(quantityNeeded) || quantityNeeded <= 0) {
      newErrors.quantityNeeded = 'Valid Quantity Needed is required';
    }
    if (materialName === 'Miscellaneous Items' && !quantityType) {
      newErrors.quantityType = 'Quantity Type is required';
    }
    return newErrors;
  };

  const handleFinish = async () => {
    try {
      const updatedPhase = {
        ...phase,
        estimatedMaterials: materials,
      };
      await updatePhase(phase._id, updatedPhase);
      onFinish();
    } catch (error) {
      console.error('Error updating phase:', error);
    }
  };

  return (
    <div className="p-6 bg-gray-200 rounded-lg shadow-md" style={{ padding: '35px', margin: '45px' }}>
      <h2 className="text-xl font-semibold mb-4">Add Materials for {phase.name}</h2>
      <div className="mb-4">
        <label className="block text-gray-700">Material Name</label>
        <select
          value={materialName}
          onChange={(e) => setMaterialName(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Select Material</option>
          {materialsList.map((material) => (
            <option key={material} value={material}>
              {material}
            </option>
          ))}
        </select>
        {errors.materialName && <p className="text-red-500 text-sm">{errors.materialName}</p>}
      </div>
      {materialName && (
        <>
          <div className="mb-4">
            <label className="block text-gray-700">Material Type</label>
            {materialName === 'Miscellaneous Items' ? (
              <input
                type="text"
                value={materialType}
                onChange={(e) => setMaterialType(e.target.value)}
                placeholder="Enter Material Type"
                className="w-full p-2 border rounded"
              />
            ) : (
              <select
                value={materialType}
                onChange={(e) => setMaterialType(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Type</option>
                {materialTypes[materialName]?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            )}
            {errors.materialType && <p className="text-red-500 text-sm">{errors.materialType}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Quantity Needed</label>
            <input
              type="text"
              value={quantityNeeded}
              onChange={(e) => setQuantityNeeded(e.target.value)}
              placeholder="Enter Quantity"
              className="w-full p-2 border rounded"
            />
            {errors.quantityNeeded && <p className="text-red-500 text-sm">{errors.quantityNeeded}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Quantity Type</label>
            {materialName === 'Miscellaneous Items' ? (
              <input
                type="text"
                value={quantityType}
                onChange={(e) => setQuantityType(e.target.value)}
                placeholder="Enter Quantity Type"
                className="w-full p-2 border rounded"
              />
            ) : (
              <select
                value={quantityType}
                onChange={(e) => setQuantityType(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Quantity Type</option>
                {quantityTypes[materialName]?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            )}
            {errors.quantityType && <p className="text-red-500 text-sm">{errors.quantityType}</p>}
          </div>
        </>
      )}
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        onClick={handleAddMaterial}
      >
        Add Material
      </button>
      {materials.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-500 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Material Name</th>
                <th className="py-3 px-6 text-left">Material Type</th>
                <th className="py-3 px-6 text-left">Quantity Needed</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {materials.map((material, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left">{material.materialName}</td>
                  <td className="py-3 px-6 text-left">{material.materialType}</td>
                  <td className="py-3 px-6 text-left">{material.quantityNeeded}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-end mt-4">
        <button
          className="bg-green-500 text-white px-4 py-2 rounded"
          onClick={handleFinish}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default AddMaterials;
